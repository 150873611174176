import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import Founders from "../components/sections/sobre-a-camarades/founders"
import LimeGradContact from "../components/sections/sobre-a-camarades/lime_grad_contact"
import Objectives from "../components/sections/sobre-a-camarades/objectives"
import OtherCamarades from "../components/sections/sobre-a-camarades/other_camarades"
import Sponsors from "../components/sections/sobre-a-camarades/sponsors"
import TeamBanner from "../components/sections/sobre-a-camarades/team_banner"
import Seo from "../components/seo"

const AboutPage = props => (
  <Layout language="pt" pathname={props.location.pathname}>
    <Seo
      title="Sobre a CAMARADES Brasil"
      lang="pt"
      description="Saiba mais sobre a CAMARADES"
    />
    <GradientJumbo
      subtitle="Sobre a CAMARADES Brasil"
      title="Uma comunidade de colaboração"
    />
    <div className="container">
      <div className="camarades-layout-globe"></div>
      <Objectives language="pt" />
      <Founders language="pt" />
      <TeamBanner language="pt" />
      <Sponsors language="pt" />
      <LimeGradContact language="pt" />
      <OtherCamarades language="pt" />
    </div>
    <Contact />
  </Layout>
)

export default AboutPage
